@tailwind base;
@tailwind utilities;
@tailwind components;

.btn41-43 {
    /* padding: 8px 60px; */
    @apply px-[40px] py-[8px]  xl:px-[60px] xl:py-[8px] ;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: transparent;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
  }
  
  .btn-41 {
    /* border: 2px solid rgb(0, 80, 68); */
    z-index: 1;
    color: rgb(255, 255, 255);
    @apply border border-white  shadow-md

  }
  
  .btn-41:after {
    
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    /* background: rgb(0, 255, 162); */
    transition: all 0.5s ease;

    @apply bg-white 
  }
  
  .btn-41:hover {
    color: rgb(94, 94, 94);
  }
  
  .btn-41:hover:after {
    left: auto;
    right: 0;
    width: 100%;
  }
  
  .btn-41:active {
    top: 2px;
  }


  



  
  
  .title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
  }
  
  /* FlipCard.css */

.flip-card {
  perspective: 1000px;
  width: 180px;
  height: 200px;
}

.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  background-color: #2196F3;
  color: white;
}

.flip-card-back {
  background-color: #aa8435;
  color: white;
  transform: rotateY(180deg);
}



  

  .background-slider {
    @apply duration-1000;
    /* background-size: cover; */
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
    background: no-repeat center center fixed;
    animation: slideBackground 15s ease-in-out infinite alternate ;
    
  }
  
  @keyframes slideBackground {
    0% {
      background-position: 0% 0%;
    }
    100% {
      @apply duration-700;
      animation-duration: 2s;
      background-position: 0% 0%;
    }
  }
  