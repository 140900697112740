/* Navbar.css */

.navbar {
    
    padding: 10px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    @apply bg-[#1b1b1b] text-[#e5e7eb]
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-container-arabic{
    @apply flex justify-between items-center flex-row-reverse
  }
  
  .navbar-logo {
    /* color: white; */
    font-size: 1.5rem;
    
    font-weight: bold;
    @apply text-[#e5e7eb];
  }
  
  .navbar-toggle {
    cursor: pointer;
  }
  
  .burger-icon {
    width: 40px;
    height: 19px;
    
    
    margin: 6px 0;
    transition: 0.4s;
    @apply text-2xl text-[#e5e7eb]
  }
  
  .navbar-menu {
    display: none;
  }
  
  .navbar-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    /* background-color: black; */
    @apply bg-[#1b1b1b]
  }
  
  .navbar-menu a {
    
    
    text-decoration: none;
    
    @apply px-4 py-0.5  text-[rgba(255,255,255,0.5)]
  }

  
  
  .navbar-menu a:hover {
    background-color: #333;
  }
  

  