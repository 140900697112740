@tailwind base;
@tailwind utilities;
@tailwind components;



.h1{
    font-family: 'Gilda Display', serif;
    font-weight: 400;
    line-height: 1.25em;
    margin: 0 0 20px 0;
    color: #fff;
}
.parentSide{
    
    @apply   lg:w-[17.5%] z-50  h-screen hidden fixed  bg-[#1b1b1b] text-[rgba(255,255,255,0.5)] lg:flex flex-col justify-between py-10 
}
.logo{
    @apply text-4xl text-[#ab8554]  
}

.icon{
    @apply hover:cursor-pointer hover:text-[#AA8453] duration-300 lg:text-lg -ml-3 lg:ml-0
}


.input{
    @apply appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-[#AA8453]
}

.background-slider {
    position: relative;
    overflow: hidden;
    height: 100%;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
  }
  