@tailwind base;
@tailwind components;
@tailwind utilities;




  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Gilda+Display&display=swap');

.titleAll{
  font-family: 'Cairo', sans-serif;
}

p{
  font-family: 'Cairo', sans-serif;
  
}

.App {
  text-align: center;
}



.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
}

.scroll-down-arrow {
  position: absolute;
  bottom: 80px;
  /* left: 50%; */
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  @apply left-[46%] md:left-[48%] lg:left-[59%]
}

.scroll-down-arrow span {
  
  display: block;
  width: 24px;
  height: 24px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  margin: 0 auto;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}




.App-header {
  transition: background-image 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: 100vh; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  transform: scale(1.4);
  animation: zoomIn 10s forwards; /* Slower initial zoom-in animation */
  transition: transform 1s ease; /* Transition for smooth zooming */

  
  
}

.brightness-75 {
  filter: brightness(75%);
}

@keyframes zoomIn {
  to {
    transform: scale(1);
    /* opacity: 0.8; */
    animation-duration: 2s;
  }
  
}

@keyframes zoomOut {
  from {
    transform: scale(1.4);
  }
  to {
    transform: scale(1);
  }
}



 .single-facility{
  border: 1px solid #2b2b2b;
  background: transparent;
    padding: 10px 0px;
    -webkit-transition: .5s;
    transition: .5s;
    /* position: relative; */
    /* z-index: 1; */
    /* margin-bottom: 30px; */
    line-height: 1;
    @apply px-0
}






.home-container {
  display: flex;
  flex-direction: column;
}

.section-container {
  background-color: #f4f4f4;
  padding: 50px;
  text-align: center;
}

.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.section-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.section-container p {
  font-size: 1.2em;
  color: #555;
}

