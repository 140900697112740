@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Cairo', sans-serif;
    @apply bg-[#1e1e1e] 
}

::-webkit-scrollbar{
    @apply hidden
}

.loader {
    position: relative;
    border:24px solid #FFF;
    
    
    border-radius:50%;
    transform: rotate(45deg);
    animation: pieFill 3s linear infinite;
  }

  @keyframes pieFill {
    0% , 19%{ border-color: #FFF #FFF #FFF #FFF }
    20% , 39%{ border-color: #AA8453 #FFF #FFF #FFF }
    40% , 59%{ border-color: #AA8453 #AA8453 #FFF #FFF }
    60% , 79%{ border-color: #AA8453 #AA8453 #AA8453 #FFF }
    80% , 100% { border-color: #AA8453 #AA8453 #AA8453 #AA8453 }
  }

  /* CircularScrollProgress.css */

.circular-scroll-progress {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #3498db;
}

.scroll-progress-bar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #ddd;
  transition: background 0.3s ease-in-out;
}

.scroll-progress-bar::before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: #3498db;
  clip-path: conic-gradient(#3498db 0% var(--progress), transparent var(--progress) 100%);
  transition: clip-path 0.3s ease-in-out;
}

  
/* ... (previous styles) */

.modal-content {
  background: #fff;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  position: relative; /* Container has relative position */
}

.full-screen-image {
  width: 100%;
  height: auto;
  max-height: 80vh; /* Adjust as needed */
  object-fit: contain;
  border-radius: 8px;
  position: absolute; /* Image has absolute position within the container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* ... (rest of the styles) */
